import { addPropertyControls, ControlType } from "framer"
import { useEffect, useState } from "react"

addPropertyControls(SplitTestImage, {
    images: {
        type: ControlType.Array,
        control: {
            type: ControlType.ResponsiveImage,
        },
    },
    imageComponents: {
        type: ControlType.Array,
        control: {
            type: ControlType.ComponentInstance,
        },
    },
    rimage: {
        type: ControlType.ResponsiveImage,
    },
    image: {
        type: ControlType.Image,
    },
    // children: {
    //     type: ControlType.ComponentInstance,
    // },
})

const variantMapping = {
    "celebrate-2": 0,
    control: 1,
}
const EXP_NAME = "lp-hero-headline"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function SplitTestImage({
    children,
    images,
    imageComponents,
    ...props
}) {
    const params = new URLSearchParams(window.location.search)
    const variant = params.get("hero") || "control"

    if (!imageComponents.length) return <div>Please select an image</div>

    console.log("images", imageComponents)
    // const imageVariant = window?.posthog.getFeatureFlag?.("lp-hero-image")

    // if (imageVariant) {
    //     // images[Number()]
    // }
    let Comp = imageComponents[variantMapping[variant]]
    if (!Comp) {
        console.log(
            "tried to load a variant that doesn't exist. variant=",
            variant
        )
        Comp = imageComponents[0]
    }

    return (
        <div style={containerStyle}>
            {Comp}
            {/*<img src={image.src} style={{ width: 50, height: 50 }} /> */}
        </div>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
